// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border, $color-hover, $background-hover, $border-hover) {
  color: $color;
  background: $background;
  border-color: $border;

  &.btn-no-focus:focus,
  &.btn-no-focus.focus {
    color: $color;
    background: $background;
    border-color: $border;
  }

  &.btn-no-focus:hover,
  &:hover,
  &.btn-no-focus:active,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color-hover;
    background: $background-hover;
    border-color: $border-hover;
  }

  &:active,  
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:not(.btn-no-focus):focus,
    &:not(.btn-no-focus).focus,
    &:active,
    &.active {
      background: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background: $color;
  }
}

// Button sizes
@mixin button-size($padding, $font-size, $line-height, $border-radius, $border-width) {
  padding: $padding;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
  border-width: $border-width;
}

// Form validation states
@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label  {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $border-color;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken($border-color, 10%);
      $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($border-color, 20%);
      @include box-shadow($shadow);
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: $text-color;
  }
}